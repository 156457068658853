@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: NoirPro, system-ui, sans-serif;
  }
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}
.parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}
.parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}
.parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }
    .content {
        height: 30vh;
    }
    h1 {
        font-size: 24px;
    }
}

.custom-bg {
    background-image: linear-gradient(
        330deg,
        hsl(199deg 100% 63%) 0%,
        hsl(197deg 100% 63%) 4%,
        hsl(196deg 100% 64%) 7%,
        hsl(194deg 100% 66%) 10%,
        hsl(192deg 100% 68%) 12%,
        hsl(191deg 100% 70%) 15%,
        hsl(189deg 100% 72%) 18%,
        hsl(187deg 100% 74%) 20%,
        hsl(185deg 99% 76%) 23%,
        hsl(184deg 97% 78%) 26%,
        hsl(183deg 95% 79%) 28%,
        hsl(181deg 93% 80%) 31%,
        hsl(180deg 91% 81%) 34%,
        hsl(179deg 93% 83%) 36%,
        hsl(177deg 96% 84%) 39%,
        hsl(175deg 99% 86%) 42%,
        hsl(175deg 100% 87%) 46%,
        hsl(175deg 100% 89%) 49%,
        hsl(174deg 100% 90%) 53%,
        hsl(174deg 100% 91%) 57%,
        hsl(174deg 100% 93%) 62%,
        hsl(173deg 100% 94%) 68%,
        hsl(173deg 100% 96%) 75%,
        hsl(173deg 100% 97%) 91%
    ) !important;
}

.org-chart-controls .ant-btn {
    margin-right: 0 !important;
    user-select: none !important;
}

.node-wrapper {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100px;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
    border: 2px solid transparent;
    position: relative;
}

.node-content {
    @apply flex flex-row justify-between items-center;
    @apply select-none;
}

.org-highlighted {
    border-color: #01689b;
}

.node-wrapper .fflag {
    @apply border border-gray-800;
}

.chart-followed .node-wrapper .inactive .org-link {
    color: rgb(211, 211, 211) !important;
}

.org-link {
    text-align: center;
    margin-top: 15px;
    user-select: none;
}

.org-link:hover {
    text-decoration: underline;
}

.org-button {
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    padding: 7px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    color: #262626;
    padding: 3px;
    user-select: none;
}

.org-settings-button-wrapper {
    border-radius: 50%;
    border: 1px dashed #d9d9d9;
    margin-left: auto;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.org-settings-button-wrapper:hover {
    border-color: #1d7ea8;
}

.org-settings-button path {
    fill: #262626;
}
